// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();

// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")
// require("@rails/actiontext")
import $ from "jquery";
window.jQuery = $;
window.$ = $;

import tippy, { roundArrow } from "tippy.js";
import "packs/space.js";

$(document).on("ready turbolinks:load", function () {
  tippy("[data-tippy-content]", {
    // content: 'title',
    animation: "shift-away-subtle",
    arrow: roundArrow,
  });

  // Menu
  $("#🍔").on("click", function (e) {
    e.preventDefault();
    var mobileMenu = $("#mobile-menu");
    if (mobileMenu.hasClass("open")) {
      mobileMenu.removeClass("open");
    } else {
      mobileMenu.addClass("open");
    }
  });

  $("[data-dropdown-trigger]").on("mouseenter", function () {
    resetSecretMenu();
    var dropdown = $(this).data("dropdown-trigger");
    $('[data-dropdown="' + dropdown + '"]').addClass("open");
  });
  $("[data-dropdown]").on("mouseleave", function () {
    resetSecretMenu();
  });
  $(".top-bar a:not([data-dropdown-trigger])").on("mouseenter", function () {
    resetSecretMenu();
  });

  $(".secret-menu .close-button").on("click", function (e) {
    e.preventDefault();
    $(this).parents(".secret-menu").removeClass("open");
  });
  // Menu end

  var OSName = "Unknown";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
    OSName = "Windows";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
    OSName = "Windows";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
    OSName = "Windows";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
    OSName = "Windows";
  if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "macOS";
  if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
  // console.log(window.navigator.userAgent);
  // console.log(OSName);
  var game_platform = OSName;
  if (OSName == "Unknown") {
    var game_platform = "Windows";
  }
  $("[data-show-platform]").each(function (index, el) {
    $(el).html(`(${game_platform})`);
  });
  $(`[data-platform-hide="${game_platform}"]`).each(function (index, el) {
    $(el).addClass("hide");
  });
  if (game_platform == "macOS") {
    $("[data-platform-href-mac]").each(function (index, el) {
      $(el).attr("href", $(el).data("platform-href-mac"));
      $(el).attr("data-platform-download", "macOS");
    });
  }
  if (game_platform == "Windows") {
    $("[data-platform-href-win]").each(function (index, el) {
      $(el).attr("href", $(el).data("platform-href-win"));
      $(el).attr("data-platform-download", "Windows");
    });
  }
  if (game_platform == "Linux") {
    $("[data-platform-href-win]").each(function (index, el) {
      $(el).attr("href", $(el).data("platform-href-linux"));
      $(el).attr("data-platform-download", "Linux");
    });
  }

  // track downloads
  $("[data-platform-download]").on("click", function (e) {
    ga(
      "send",
      "event",
      "Game",
      "Download",
      "Squarelaxy for " + $(this).data("platform-download")
    );
  });

  $("#show-newsletter-form").on("click", function () {
    $(this).addClass("hide");
    $("#mc_embed_signup").removeClass("hide");
    $("#mce-EMAIL").focus();
  });

  $("[data-platform-download]").on("click", function (e) {
    setTimeout(function () {
      $("html, body").animate(
        {
          scrollTop: $("#download-wait-wrapper").offset().top,
        },
        500
      );
      setTimeout(function () {
        $("#download-wait").removeClass("hide");
      }, 1000);
    }, 300);
  });

  $("[data-close]").on("click", function () {
    $(this).parent("[data-closable]").remove();
  });
});

function resetSecretMenu() {
  $(".secret-menu").removeClass("open");
}
